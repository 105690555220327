import React from "react";
import { Col, Row } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

// styles
const Projecttitle = styled.h1`
  color: ${({ theme }) => theme.colours.common.black};
  font-weight: 400;
  font-family: ${({ theme }) => theme.font.headings};
  text-transform: uppercase;
  margin: 15px 0 0 0;
  font-size: 2.3rem;
`

const Projectsubtitle = styled.h2`
  color: ${({ theme }) => theme.colours.primary.main};
  font-weight: 400;
  font-family: ${({ theme }) => theme.font.headings};
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 1.5rem;
`


const Projectdescription = styled.div`
  color: ${({ theme }) => theme.colours.common.black};
  margin-bottom: 50px;

  p {
    line-height: 1.2;
  }

  & a {
    color: ${({ theme }) => theme.colours.primary.main};
    text-decoration: none;
    transition: ${({ theme }) => theme.animations.link};
  }
  
  & a:hover {
    color: ${({ theme }) => theme.colours.primary.main};
    font-weight: bold;
  }
  
`

const ProjectGallery = styled.div`
  line-height: 0;
  -webkit-column-count: 2;
  -webkit-column-gap:   2px;
  -moz-column-count:    2;
  -moz-column-gap:      2px;
  column-count:         2;
  column-gap:           2px;  


`

const Projectimg = styled(GatsbyImage)`
  width: 100% !important;
  height: auto !important;
  margin: 2px;
`

const Projectrow = styled(Row)`
  margin-bottom: 50px;
  padding: 10px;
`

const Undercol = styled(Col)`
  margin-top: 20px;
`

function Projectslist({ children }) {
 
  const data = useStaticQuery(graphql`
  query {
    directus {
      projects {
        id
        content
        content_under
        project_image {
          id
          title
          imageFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
        image2 {
          id
          title
          imageFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
            }
          }
        }
        image3 {
          id
          title
          imageFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
            }
          }
        }
        image4 {
          id
          title
          imageFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
            }
          }
        }
        image5 {
          id
          title
          imageFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
            }
          }
        }
        image6 {
          id
          title
          imageFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
            }
          }
        }
        image7 {
          id
          title
          imageFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
            }
          }
        }
        image8 {
          id
          title
          imageFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
            }
          }
        }
        slug
        subtitle
        title
      }
    }
  }
  
  `)

  const projectNode = data.directus.projects;
 
  return (
    
    <>
    
        {projectNode.map((projectItem, i) => 
        (
          <Projectrow key={i}>
{(() => {
        if (i % 2 === 0) {
          return (
          <>
            
            <Col xs={12} md={6}>
            <ProjectGallery>
              <Projectimg image={projectItem.project_image.imageFile.childImageSharp.gatsbyImageData} alt={projectItem.title} objectPosition="center center" />
              <Projectimg image={projectItem.image2.imageFile.childImageSharp.gatsbyImageData} alt={projectItem.title} objectPosition="center center" />
              <Projectimg image={projectItem.image3.imageFile.childImageSharp.gatsbyImageData} alt={projectItem.title} objectPosition="center center" />
              <Projectimg image={projectItem.image4.imageFile.childImageSharp.gatsbyImageData} alt={projectItem.title} objectPosition="center center" />
              <Projectimg image={projectItem.image5.imageFile.childImageSharp.gatsbyImageData} alt={projectItem.title} objectPosition="center center" />
              <Projectimg image={projectItem.image6.imageFile.childImageSharp.gatsbyImageData} alt={projectItem.title} objectPosition="center center" />
              <Projectimg image={projectItem.image7.imageFile.childImageSharp.gatsbyImageData} alt={projectItem.title} objectPosition="center center" />
              <Projectimg image={projectItem.image8.imageFile.childImageSharp.gatsbyImageData} alt={projectItem.title} objectPosition="center center" />
              </ProjectGallery>
            </Col>
            
            <Col xs={12} md={6}>
              <Projecttitle>{projectItem.title}</Projecttitle>
              <Projectsubtitle>{projectItem.subtitle}</Projectsubtitle>
              <Projectdescription dangerouslySetInnerHTML={{
                    __html: projectItem.content,
                }} />
                <Projectdescription dangerouslySetInnerHTML={{
                      __html: projectItem.content_under,
                  }} />
            </Col>
            {/* <Undercol xs={12} md={12}>
              <Projectdescription dangerouslySetInnerHTML={{
                      __html: projectItem.content_under,
                  }} />
            </Undercol> */}
          </>
          )
        } else {
          return (
            <>
            <Col xs={12} md={6} className="px-0 d-sm-block d-lg-none">
            <ProjectGallery>
              <Projectimg image={projectItem.project_image.imageFile.childImageSharp.gatsbyImageData} alt={projectItem.title} objectPosition="center center" />
              <Projectimg image={projectItem.image2.imageFile.childImageSharp.gatsbyImageData} alt={projectItem.title} objectPosition="center center" />
              <Projectimg image={projectItem.image3.imageFile.childImageSharp.gatsbyImageData} alt={projectItem.title} objectPosition="center center" />
              <Projectimg image={projectItem.image4.imageFile.childImageSharp.gatsbyImageData} alt={projectItem.title} objectPosition="center center" />
              <Projectimg image={projectItem.image5.imageFile.childImageSharp.gatsbyImageData} alt={projectItem.title} objectPosition="center center" />
              <Projectimg image={projectItem.image6.imageFile.childImageSharp.gatsbyImageData} alt={projectItem.title} objectPosition="center center" />
              <Projectimg image={projectItem.image7.imageFile.childImageSharp.gatsbyImageData} alt={projectItem.title} objectPosition="center center" />
              <Projectimg image={projectItem.image8.imageFile.childImageSharp.gatsbyImageData} alt={projectItem.title} objectPosition="center center" />
            </ProjectGallery>
            </Col>
            <Col xs={12} md={6}>
              <Projecttitle>{projectItem.title}</Projecttitle>
              <Projectsubtitle>{projectItem.subtitle}</Projectsubtitle>
              <Projectdescription dangerouslySetInnerHTML={{
                    __html: projectItem.content,
                }} />
                <Projectdescription dangerouslySetInnerHTML={{
                      __html: projectItem.content_under,
                  }} />
            </Col>
            <Col xs={12} md={6} className="d-none d-lg-block">
            <ProjectGallery>
              <Projectimg image={projectItem.project_image.imageFile.childImageSharp.gatsbyImageData} alt={projectItem.title} objectPosition="center center" />
              <Projectimg image={projectItem.image2.imageFile.childImageSharp.gatsbyImageData} alt={projectItem.title} objectPosition="center center" />
              <Projectimg image={projectItem.image3.imageFile.childImageSharp.gatsbyImageData} alt={projectItem.title} objectPosition="center center" />
              <Projectimg image={projectItem.image4.imageFile.childImageSharp.gatsbyImageData} alt={projectItem.title} objectPosition="center center" />
              <Projectimg image={projectItem.image5.imageFile.childImageSharp.gatsbyImageData} alt={projectItem.title} objectPosition="center center" />
              <Projectimg image={projectItem.image6.imageFile.childImageSharp.gatsbyImageData} alt={projectItem.title} objectPosition="center center" />
              <Projectimg image={projectItem.image7.imageFile.childImageSharp.gatsbyImageData} alt={projectItem.title} objectPosition="center center" />
              <Projectimg image={projectItem.image8.imageFile.childImageSharp.gatsbyImageData} alt={projectItem.title} objectPosition="center center" />
            </ProjectGallery>
            </Col>
            {/* <Undercol xs={12} md={12}>
              <Projectdescription dangerouslySetInnerHTML={{
                      __html: projectItem.content_under,
                  }} />
            </Undercol> */}
            </>
          )
        }
      })()}
            </Projectrow>
             ))}        
    
  </>
  );
}

export default Projectslist;


// <>
// <Container>
//   <Row>
//       {ProjectNode.map((projectItem, i) => 
//       (
//           <Col xs={12} md={4} key={i}>
//             <Projectimg image={projectItem.Projectitation_image.imageFile.childImageSharp.gatsbyImageData} alt={projectItem.title} objectPosition="center center" />
//            alt template inline
//             {/* {
//               i % 2 === 0 ? <Projecttitle>{projectItem.title}</Projecttitle> : <Projecttitle>{projectItem.title}</Projecttitle>
//             } */}

//alt template multi-lined
// {(() => {
//       if (i % 2 === 0) {
//         return (
//           <div>someCase</div>
//         )
//       } else {
//         return (
//           <div>catch all</div>
//         )
//       }
//     })()}
//             <Projectdescription>{projectItem.description}</Projectdescription>
          
//           </Col> ))}        
//   </Row>
// </Container>

// </>


// {
//   projectItem.project_image && projectItem.project_image.imageFile  ? <Projectimg image={projectItem.project_image.imageFile.childImageSharp.gatsbyImageData} alt={projectItem.title} objectPosition="center center" /> : <p></p>
// }